import {defineStore} from 'pinia';
import {router, usePage} from "@inertiajs/vue3";

export const useAppStore = defineStore('app', {
    state: () => ({
        route: route(),
        page: usePage(),
        showSwitchCompany: false,
    }),
    actions: {
        setCurrentRoute() {
            this.route = route();
        },
        setCurrentPage() {
            this.page = usePage();
        },
        setSwitchCompany(toggle) {
            this.showSwitchCompany = toggle;
        },
    }
})

if (typeof window !== 'undefined') {
    router.on('navigate', () => {
        const app = useAppStore();
        app.setCurrentRoute();
        app.setCurrentPage();
    });
}
